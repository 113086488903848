<template>
    <div v-show="props.compType === 'EvaluateTeachers'">
        <div style="margin-bottom: 15px">
            <div class="description">
                <div class="bgi"></div>
                <div class="desc">{{ props.description }}</div>
            </div>
        </div>
        <div v-if="props.children[selectIndex]">
            <el-form-item label="标题">
                <el-input
                    class="input"
                    v-model="props.children[selectIndex].label"
                ></el-input>
            </el-form-item>
            <el-form-item label="选项个数">
                <el-input-number
                    v-model="props.children[selectIndex].num"
                    :min="1"
                    :max="10"
                    @change="changeNum(props.children[selectIndex])"
                ></el-input-number>
            </el-form-item>
            <el-table
                :data="props.children[selectIndex].options"
                border
                :header-cell-style="{
                    background: '#F2F2F2',
                    color: '#333333',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: '400',
                }"
            >
                <el-table-column
                    prop="value"
                    width="128"
                    label="选项名称"
                >
                    <template slot-scope="scope">
                        <el-input
                            class="input"
                            v-model="scope.row.label"
                            maxlength="10"
                        ></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="label"
                    width="128"
                    label="对应得分"
                >
                    <template slot-scope="scope">
                        <div class="inputContainer">
                            <div>
                                <el-input-number
                                    :controls="false"
                                    v-model="scope.row.value"
                                    :min="0"
                                    :max="100"
                                    :precision="2"
                                    @change="
                                        changeNum2(
                                            props.children[selectIndex],
                                            scope,
                                        )
                                    "
                                ></el-input-number>
                            </div>
                            <div class="card">分</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import {mapState} from "vuex";
export default {
    name: "EvaluateTeachers",
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            originRouters: (state) => state.originRouters,
        }),
        transferIcon() {
            return require("@/assets/images/transfer-icon.png");
        },
    },
    props: ["props", "getFormId", "selctOption"],
    data() {
        return {
            list: [],
            selectIndex: "",
        };
    },
    created() {
        this.init();
    },

    methods: {
        /**
         * @Description: 初始化
         * @Author: 周浩
         * @Date: 2024-8-7 13:53:16
         */
        init() {
            this.list = this.props.children;
        },
        /**
         * @Description: 选项个数改变
         * @Author: 周浩
         * @Date: 2024-8-7 13:53:16
         */
        changeNum(item) {
            console.debug("changeNum", item);
            let oldLength = item.options.length;
            let newLength = item.num;
            if (oldLength > newLength) {
                item.options = item.options.slice(0, newLength);
            } else {
                let n = newLength - oldLength;
                for (let i = 0; i < n; i++) {
                    item.options.push({
                        value: 0,
                        label: "选项",
                    });
                }
            }
        },
        /**
         * @Description: 分数改变
         * @Author: 周浩
         * @Date: 2024-8-7 13:53:16
         */
        changeNum2(item, scope) {
            let data = item.options[scope.$index];
            if (data.value == undefined) {
                this.$nextTick(() => {
                    data.value = 0.0;
                });
            }
        },
    },
    watch: {
        selctOption: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.init();
                if (newValue || newValue == 0) {
                    this.selectIndex = newValue;
                }
            },
        },
    },
    mounted() {},
};
</script>
<style lang="scss" scoped>
.inputContainer {
    display: flex;
    align-items: center;
    .card {
        margin-left: -2px;
        flex-shrink: 0;
        width: 33px;
        height: 33px;
        background: #f4f4f4;
        border-radius: 2px;
        border: 1px solid #e1e3e6;
        line-height: 33px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
    }
}
.description {
    display: flex;
    background: #e8f0ff;
    border-radius: 4px;
    padding: 9px 10px;
    .bgi {
        margin-top: 3px;
        width: 12px;
        height: 12px;
        flex-shrink: 0;
        margin-right: 8px;
        background-size: 100% 100%;
        background-image: url("@/assets/images/tis.png");
    }
    .desc {
        flex: 1;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #333333;
        line-height: 20px;
    }
}
</style>
